.scroll-area {
    height: 100%;
    overflow-y: auto;
    padding: 0 10px;
}

.scroll-area::-webkit-scrollbar {
    width: 0;
}

.scroll-area.visible-scrollbar::-webkit-scrollbar {
    width: 5px;
}

.scroll-area::-webkit-scrollbar-thumb {
    background-color: #fff6;
    border-radius: 10px;
}

.scroll-area::-webkit-scrollbar-track {
    background-color: #fff2;
    border-radius: 10px;
}

.scroll-area::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-primary);
}

.scroll-area::-webkit-scrollbar-thumb:active {
    background-color: var(--color-primary);
}
