.header-widget {
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.5);
    /* background-color: #1f242baa; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    z-index: 1;
    margin: 10px;
}