.layout-dashboard-sider {
    padding: 10px;
    height: 100vh;
    overflow: hidden;
    position: sticky;
    top: 0;
}

.layout-dashboard-sider-logo {
    padding: 20px 0 30px 0;
    top: 0;
    background-image: linear-gradient(180deg, var(--color-gray-9) 70%, transparent 100%);
    z-index: 1;
}