@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&family=Almarai:wght@300;400;700;800&display=swap');

/* * {
    font-family: 'Almarai', sans-serif!important;
} */

:root {
    --color-primary: #f07d00;

    --color-gray-9: #181c21;
    --color-gray-8: #343A40;
    --color-gray-7: #495057;
    --color-gray-6: #6C757D;
    --color-gray-5: #ADB5BD;
    --color-gray-4: #CED4DA;
    --color-gray-3: #DEE2E6;
    --color-gray-2: #E9ECEF;
    --color-gray-1: #F8F9FA;
}

.bg-gray-9 {
    background-color: var(--color-gray-9)!important;
}

.bg-gray-8 {
    background-color: var(--color-gray-8)!important;
}

.bg-gray-7 {
    background-color: var(--color-gray-7)!important;
}

.bg-gray-6 {
    background-color: var(--color-gray-6)!important;
}

.bg-gray-5 {
    background-color: var(--color-gray-5)!important;
}

.bg-gray-4 {
    background-color: var(--color-gray-4)!important;
}

.bg-gray-3 {
    background-color: var(--color-gray-3)!important;
}

.bg-gray-2 {
    background-color: var(--color-gray-2)!important;
}

.bg-gray-1 {
    background-color: var(--color-gray-1)!important;
}

.text-gray-9 {
    color: var(--color-gray-9)!important;
}

.text-gray-8 {
    color: var(--color-gray-8)!important;
}

.text-gray-7 {
    color: var(--color-gray-7)!important;
}

.text-gray-6 {
    color: var(--color-gray-6)!important;
}

.text-gray-5 {
    color: var(--color-gray-5)!important;
}

.text-gray-4 {
    color: var(--color-gray-4)!important;
}

.text-gray-3 {
    color: var(--color-gray-3)!important;
}

.text-gray-2 {
    color: var(--color-gray-2)!important;
}

.text-gray-1 {
    color: var(--color-gray-1)!important;
}

.text-primary {
    color: var(--color-primary)!important;
}

.text-primary-hover:hover {
    color: var(--color-primary)!important;
}

.bg-primary {
    background-color: var(--color-primary)!important;
}